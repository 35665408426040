import { getService as service } from '../util/angular_helpers.js';

/**
 * Select the template based on the page's first element's coachmarkType,
 * since we are using sp-overlay we can only render 1 coachmark per page
 *
 * @param {*} coachmarkType
 * @param {*} targetElement
 */
export async function refreshCoachmarks() {
  let target = document.querySelector('[data-coachmark-type]');
  if (!target) {
    return;
  }

  const coachmarkType = target.getAttribute('data-coachmark-type');
  const UserPrefsService = await service('UserPrefsService');
  return UserPrefsService.getUserPrefs().then(userPrefs => {
    if (userPrefs.hideLibrariesCoachmark && userPrefs.hideLibrariesCoachmark.includes(coachmarkType)) {
      return;
    }

    // If the target has a view type data attribute, we need to attach the coachmark to the correct target
    // Otherwise in Angular, the coachmark will be attached to the first target it finds, which in the filtered
    // family list, the first target seems to consistently be the 'grid' view target
    if (target.hasAttribute('data-coachmark-view-type')) {
      target = document.querySelector(`[data-coachmark-view-type="${coachmarkType}-${userPrefs.selectedViewType}-view"]`);
    }
    if (!target) {
      return;
    }

    return displayCoachmark(target);
  });
}

function displayCoachmark(targetElement) {
  showCoachmarkIndicator(targetElement);
  showCoachmark(targetElement);
}

/**
 * The coach indicator will only show up when placed in the same parent as the target element
 * @param {Element} targetElement
 * @returns {Element} wrapper
 */
export function showCoachmarkIndicator(targetElement) {
  const coachmarkType = targetElement.getAttribute('data-coachmark-type');

  if (targetElement.nextElementSibling && targetElement.nextElementSibling.tagName === 'SP-COACH-INDICATOR') {
    return;
  }

  const indicator = document.createElement('sp-coach-indicator');
  indicator.id = `coachmark-indicator-${coachmarkType}`;
  const wrapper = document.createElement('div');
  wrapper.classList.add('coachmark-indicator-wrapper');
  if (targetElement.hasAttribute('data-coachmark-view-type')) {
    const viewType = targetElement.getAttribute('data-coachmark-view-type');
    wrapper.classList.add(viewType);
  }

  const slotName = targetElement.getAttribute('slot');
  if (slotName) {
    wrapper.setAttribute('slot', slotName);
  }

  targetElement.insertAdjacentElement('afterend', wrapper);
  wrapper.appendChild(targetElement);
  wrapper.appendChild(indicator);

  return wrapper;
}

export function showCoachmark(targetElement) {
  const coachmarkType = targetElement.getAttribute('data-coachmark-type');
  const template = document.querySelector(`[data-coachmark-template=${coachmarkType}]`);

  if (!template || document.querySelector(`dialog#${coachmarkType}-dialog`)) {
    return;
  }

  const indicatorWrapper = document.querySelector('.coachmark-indicator-wrapper');
  const clone = document.importNode(template.content, true);

  indicatorWrapper.appendChild(clone);
}

/**
 * Remove the dialog and coachmark indicator from the DOM for when user toggles between view types without
 * a page reload, since we need to ensure only 1 of the same coachmark is attached to either the same or different target
 */
export const removeCoachmark = () => {
  const indicatorWrapper = document.querySelector('.coachmark-indicator-wrapper');
  if (!indicatorWrapper) {
    return;
  }

  const targetElement = indicatorWrapper.querySelector('[data-coachmark-type]');
  if (!targetElement) {
    return;
  }

  indicatorWrapper.replaceWith(targetElement);
};

export const handleCloseClick = (event) => {
  const indicatorWrapper = event.target.closest('.coachmark-indicator-wrapper');
  if (!indicatorWrapper) {
    return;
  }

  const targetElement = indicatorWrapper.querySelector('[data-coachmark-type]');
  if (!targetElement) {
    return;
  }

  const coachmarkType = targetElement.getAttribute('data-coachmark-type');
  const UserPrefsService = service('UserPrefsService');
  UserPrefsService.updatePrefs({ hideLibrariesCoachmark: coachmarkType });
  indicatorWrapper.replaceWith(targetElement);
  removeCoachmark();
};

export const registerEventHandlers = () => {
  window.addEventListener('primary', handleCloseClick);
};
