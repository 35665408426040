// The family card and variation card's action components are lower priority and can be
// delay loaded after the rest of the card.
void import(
  /* webpackChunkName: "card-actions" */
  '@adobe-fonts/site/components/add-to-web-project-button/AddToWebProjectButton.js');

void import(
  /* webpackChunkName: "card-actions" */
  '@adobe-fonts/site/components/favorite-button/FavoriteButton.js');

void import(
  /* webpackChunkName: "card-actions" */
  '@adobe-fonts/site/components/ccx/open-in-ccx-button/OpenInCCXButton.js');

void import(
  /* webpackChunkName: "card-actions" */
  '@adobe-fonts/site/components/sync-button/SyncButton.js');
