import { EVENT_NAME as SAVE_TO_LIBRARY_EVENT } from '@adobe-fonts/site/events/SaveToLibraryIconClickEvent.js';
import { EVENT_NAME as SAVE_TO_LIBRARY_LOAD_EVENT } from '@adobe-fonts/site/events/SaveToLibraryMenuLoadedEvent.js';
import { getService as service } from '../../util/angular_helpers.js';
import { getLibraryElementData } from '../../util/libraries.js';

export class SaveToLibrary {
  constructor() {
    this.libraries = [];
  }

  handleAuthenticationWithPostLoginAction = async (name, data) => {
    const AuthenticationService = service('AuthenticationService');
    await AuthenticationService.handleAuthenticationWithPostLoginAction(name, data);
  };

  handleSaveToLibraryEvent = async (event) => {
    const eventData = {};
    let libraryElementsContainer;
    if (this._isOnFamilyDetailsPage(event.target)) {
      libraryElementsContainer = this._getClosestFamilyPage(event.target);
      eventData['familySlug'] = this._getSlug(event.target);
    }
    if (this._isOnFamilyCard(event.target)) {
      libraryElementsContainer = this._getClosestFamilyCard(event.target);
      eventData['familySlug'] = this._getSlug(event.target);
    }
    if (this._isOnVariationCard(event.target)) {
      libraryElementsContainer = this._getClosestVariationCard(event.target);
      eventData['font_variation_id'] = this._getVariationId(event.target);
    }
    await this.handleAuthenticationWithPostLoginAction('open_library_menu', eventData);

    // Swap out the icon for the menu with full libraries functionality on click.
    // This is done to lazy load the menu component, as it is rendered on every card.
    await import('@adobe-fonts/site/components/libraries/save-library-menu/SaveLibraryMenu.js');
    const saveButton = event.target;
    const slotName = saveButton.getAttribute('slot');

    const menu = document.createElement('af-save-library-menu');
    if (saveButton.classList.length > 0) {
      menu.classList = saveButton.classList;
    }
    if (slotName) {
      menu.setAttribute('slot', slotName);
    }
    menu.libraryElements = getLibraryElementData(libraryElementsContainer);
    menu.hideTooltip = saveButton.hideTooltip;
    menu.showLightButton = saveButton.showLightButton;
    menu.showLabel = saveButton.showLabel;
    menu.open = true;

    if (this.libraries.length > 0) {
      menu.libraries = this.libraries;
    }

    event.target.replaceWith(menu);
  };

  handleSaveToLibraryLoadEvent = (event) => {
    this.libraries = event.detail.libraries;
    this._getAllSaveLibraryMenus().forEach(menu => {
      menu.libraries = this.libraries;
    });
  };

  _getAllSaveLibraryMenus = () => {
    return document.querySelectorAll('af-save-library-menu');
  };

  _getSlug = (element) => {
    const familyElement = element.closest('[data-slug], [data-family-slug]');
    return familyElement?.getAttribute('data-family-slug') || familyElement?.getAttribute('data-slug');
  };

  _getVariationId = (element) => {
    const variationElement = element.closest('[data-font-variation-id], [data-id]');
    if (variationElement) {
      const id = variationElement.getAttribute('data-font-variation-id') || variationElement.getAttribute('data-id');
      return parseInt(id, 10);
    }
    return null;
  };

  _isOnFamilyDetailsPage(element) {
    return !!this._getClosestFamilyPage(element);
  }

  _isOnFamilyCard(element) {
    return !!this._getClosestFamilyCard(element);
  }

  _isOnVariationCard(element) {
    return !!this._getClosestVariationCard(element);
  }

  _getClosestFamilyPage(element) {
    return element.closest('.adobe-fonts-family');
  }

  _getClosestFamilyCard(element) {
    return element.closest('af-family-card, .adobe-fonts-family-card');
  }

  _getClosestVariationCard(element) {
    return element.closest('af-variation-card, .font-variation-card');
  }
}

export function registerEventHandlers(element) {
  const saveToLibrary = new SaveToLibrary();
  element.addEventListener(SAVE_TO_LIBRARY_EVENT, saveToLibrary.handleSaveToLibraryEvent);
  element.addEventListener(SAVE_TO_LIBRARY_LOAD_EVENT, saveToLibrary.handleSaveToLibraryLoadEvent);
}
